export enum MatchedPredictors {
    UNKNOWN = 1,
    FLOOR = 10,
    TOTALPRICE = 11,
    PRICEPERSIZE = 12,
    FLOOR_TOTALPRICE = 20,
    FLOOR_PRICEPERSIZE = 21,
    TOTALPRICE_PRICEPERSIZE = 22,
    FLOOR_TOTALPRICE_PRICEPERSIZE = 30,
}
